import React, { useState, useEffect } from 'react'
import Alphafold from '../../components/Alphafold'
import Link from '../../components/Link'
import logo from 'img/logo-icon.png'
import { graphql } from 'gatsby'
import cx from 'classnames'

import './alphafold-kiosk.scss'

const AlphafoldPage = ({ data }) => {
	const [index, setIndex] = useState(0)

	const { files } = data.alphafold.page.frontmatter

	const display = ['ball+stick', 'cartoon', 'surface']
	const color = ['hydrophobicity', 'bfactor', 'occupancy']

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prev) => {
				if (prev < files.length - 1) {
					return prev + 1
				} else {
					return 0
				}
			})
		}, 20000)

		return () => clearInterval(interval)
	}, [])

	return (
		<div className={cx('alphafold-kiosk')}>
			<Link to='/'>
				<img src={logo} />
			</Link>
			<Alphafold
				file={files[index]}
				displayStyle={
					display[Math.floor(Math.random() * display.length)]
				}
				color={color[Math.floor(Math.random() * color.length)]}
				backgroundColor='black'
				fullScreen
			/>
		</div>
	)
}

export default AlphafoldPage

export const query = graphql`
	query Alphafold {
		alphafold: file(
			sourceInstanceName: { eq: "pages" }
			relativePath: { eq: "alphafold.md" }
		) {
			page: childMarkdownRemark {
				frontmatter {
					files
				}
			}
		}
	}
`
