import React, { useState, useEffect, useRef } from 'react'
import * as NGL from 'ngl'
import cx from 'classnames'
import './Alphafold.scss'

function useWindowEvent(type, fn, opts, deps) {
	useEffect(() => {
		window.addEventListener(type, fn, opts)

		return () => {
			window.removeEventListener(type, fn)
		}
	}, deps)
}

const Alphafold = ({
	file,
	backgroundColor = 'white',
	fullScreen = false,
	displayStyle,
	color,
}) => {
	const [model, setModel] = useState(null)
	const [viewer, setViewer] = useState(null)
	const [viewport, setViewport] = useState(500)
	const containerRef = useRef()

	useWindowEvent('resize', (e) => {
		setViewport({
			height: window.innerHeight,
			width: window.innerWidth,
		})
	})

	useWindowEvent(
		'wheel',
		(e) => {
			if (
				containerRef.current &&
				containerRef.current.contains(e.target)
			) {
				e.preventDefault()
			}
		},
		{ passive: false }
	)

	useEffect(() => {
		setViewport({ height: window.innerHeight, width: window.innerWidth })
	}, [])

	useEffect(() => {
		if (containerRef.current) {
			createViewer()
		}
	}, [containerRef])

	useEffect(() => {
		if (model) {
			model.addRepresentation(displayStyle || 'cartoon', {
				color: color || 'hydrophobicity',
			})
			model.autoView()
			model.stage.animationControls.zoom(-80, 10)
		}
	}, [model, displayStyle])

	useEffect(() => {
		loadFile()
	}, [file, viewer])

	useEffect(() => {
		if (viewer && fullScreen) {
			viewer.handleResize()
		}
	}, [viewport])

	const classNames = cx('alphafold')

	const style = {
		height: fullScreen ? `${viewport.height}px` : '350px',
		width: fullScreen ? `${viewport.width}px` : '100%',
	}
	return (
		<>
			<div ref={containerRef} className={classNames} style={style} />
		</>
	)

	function createViewer() {
		const nglViewer = new NGL.Stage(containerRef.current, {
			backgroundColor,
			defaultRepresentation: true,
			tooltip: false,
		})

		setViewer(nglViewer)
	}

	function loadFile() {
		if (viewer) {
			viewer.removeAllComponents()

			if (file) {
				viewer.loadFile(file).then((o) => {
					setModel(o)
				})
			}

			viewer.setSpin(true)
		}
	}
}

export default Alphafold
